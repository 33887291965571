import React from 'react'
import PropTypes from 'prop-types'
import { graphql } from 'gatsby'
import {
  Wrapper,
  ImageWrapper,
  Inner,
  Content,
  Heading,
  Preamble,
  ButtonGroup,
  StyledButton
} from './styles'

const StartHero = ({
  backgroundImage,
  title,
  textContent,
  addPrimaryLink,
  primaryLinkTitle,
  primaryLinkUrl,
  addSecondaryLink,
  secondaryLinkTitle,
  secondaryLinkUrl
}) => {
  const hasLinks = addPrimaryLink || addSecondaryLink

  return (
    <Wrapper>
      <ImageWrapper
        style={{ position: 'absolute' }}
        fluid={backgroundImage.fluid}
      />
      <Inner>
        <Content>
          <Heading>{title}</Heading>
          <Preamble
            className="rte"
            dangerouslySetInnerHTML={{ __html: textContent }}
          />
          {hasLinks && (
            <ButtonGroup>
              {addPrimaryLink && primaryLinkTitle && primaryLinkUrl && (
                <StyledButton
                  type="link"
                  text={primaryLinkTitle}
                  colorTheme="light"
                  icon="arrow-short"
                  to={primaryLinkUrl}
                />
              )}
              {addSecondaryLink && secondaryLinkTitle && secondaryLinkUrl && (
                <StyledButton
                  type="link"
                  text={secondaryLinkTitle}
                  colorTheme="light"
                  icon="arrow-short"
                  to={secondaryLinkUrl}
                />
              )}
            </ButtonGroup>
          )}
        </Content>
      </Inner>
    </Wrapper>
  )
}

export default StartHero

StartHero.propTypes = {
  title: PropTypes.string.isRequired,
  textContent: PropTypes.string.isRequired,
  backgroundImage: PropTypes.shape({
    fluid: PropTypes.shape({
      aspectRatio: PropTypes.number.isRequired,
      base64: PropTypes.string.isRequired,
      sizes: PropTypes.string.isRequired,
      src: PropTypes.string.isRequired,
      srcSet: PropTypes.string.isRequired
    }).isRequired
  }).isRequired,
  addPrimaryLink: PropTypes.bool,
  primaryLinkTitle: PropTypes.string,
  primaryLinkUrl: PropTypes.string,
  addSecondaryLink: PropTypes.bool,
  secondaryLinkTitle: PropTypes.string,
  secondaryLinkUrl: PropTypes.string
}

StartHero.defaultProps = {
  addPrimaryLink: true,
  primaryLinkTitle: 'Button 1',
  primaryLinkUrl: 'https://sould.se',
  addSecondaryLink: true,
  secondaryLinkTitle: 'Button 2',
  secondaryLinkUrl: 'https://sould.se'
}

export const StartHeroQuery = graphql`
  fragment HeroFragment on DatoCmsHero {
    title
    textContent
    addPrimaryLink
    primaryLinkTitle
    primaryLinkUrl
    addSecondaryLink
    secondaryLinkTitle
    secondaryLinkUrl
    backgroundImage {
      fluid(maxWidth: 2048, sizes: "375, 750, 850, 1024, 1700, 2048") {
        ...GatsbyDatoCmsFluid
      }
    }
  }
`