import styled from 'styled-components'
import BackgroundImage from 'gatsby-background-image'
import Img from 'gatsby-image'
import siteTheme from '../../layout/theme'
import {
  breakpoint, HalignContentStyles, BlockPaddingVertical
} from '../../layout/mixins'
import PinIcon from '../../layout/icons/pin.svg'
import Quote from '../Quote'
import Arrow from '../../layout/icons/arrow-down.svg'

export const Grid = styled.div`
  width: 100%;
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
  background: ${({ theme }) => theme.backgroundColor};

  padding-left: 0;
  padding-right: 0;
  
  ${({ alignRight }) => alignRight === true && `
    flex-direction: row-reverse;
  `}
`

export const Item = styled.div`
  width: 100%;
  position: relative;
  order: 2;

  ${breakpoint.up('phone')`
    width: 50%;
    order: initial;
  `}
`

export const Content = styled.article`
  padding: ${({ theme }) => theme.gutterLarge};

  ${({ asHero }) => !asHero && `
    padding-bottom: 0;
  `}

  ${breakpoint.up('phone')`
    ${({ asHero }) => !asHero && `
      padding-bottom: 0;
      padding-top: 0;
    `}
  `}

  ${breakpoint.up('tablet')`
    ${({ alignRight, theme, asHero }) => alignRight && asHero == false ? `
      padding-right: 0;
    ` : `
      padding-left: 0;
    `}
  `}

  ${breakpoint.up('desktop')`
    ${({ alignRight, theme }) => alignRight ? `
      padding-left: ${theme.blockpadding};
    ` : `
      padding-right: ${theme.blockpadding};
    `}
  `}

  .as-hero & {
    ${breakpoint.up('tablet')`
      ${HalignContentStyles}
      ${BlockPaddingVertical}
    `}
  }
`

export const BackgroundWrapper = styled.div`
  width: 100%;
  padding-bottom: 75%;
  order: 1;
  position: relative;

  ${breakpoint.up('phone')`
    width: 50%;
    order: initial;
    padding-bottom: 0;
  `}

  ${breakpoint.up('desktop')`
    min-height: 25rem;
  `}

  ${breakpoint.up('large')`
    min-height: 30rem;
  `}
`

export const Background = styled(BackgroundImage)`
  width: 100%;
  height: 100%;
  
  ${breakpoint.down('phone')`
    position: absolute !important;
  `}
`

export const ImageWrapper = styled.div`
  width: 100%;
  order: 1;
  position: relative;

  ${({ alignRight }) => (alignRight === true ? `
    padding-right: 0 !important;
  ` : `
    padding-left: 0 !important;
  `)}

  ${breakpoint.up('phone')`
    width: 50%;
    order: initial;
  `}
`

export const Image = styled(Img)`
  width: 100%;
  height: 400px;

  ${breakpoint.up('phone')`
    border-radius: ${({ theme }) => theme.imageRadius};
  `}
`

export const ButtonAlign = styled.div`
  padding-top: ${({ theme }) => theme.gutterMedium};
`

export const Address = styled.div`
  padding-top: ${({ theme }) => theme.gutter};
  display: flex;
`

export const AddressText = styled.div`
  font-size: ${({ theme }) => theme.fontsizeSmall};
  line-height: 2;
`

export const Pin = styled(PinIcon)`
  fill: ${({ theme }) => theme.colorAccent};
  margin-right: ${({ theme }) => theme.gutter};
`
export const QuoteAlign = styled.div`
  padding-top: ${({ theme }) => theme.gutter};
`
export const StyledQuote = styled(Quote)`
  font-size: ${({ theme }) => theme.fontsizeSmall};
`
export const ArrowDown = styled(Arrow)`
  fill: ${({ theme }) => theme.colorBlack};
`
export const ShortcutAlign = styled.div`
  position: absolute;
  bottom: 0;
  transform: translate(-50%, 50%);
`

export const Shortcut = styled.button`
    padding: ${({ theme }) => theme.gutter};
`

export const themes = {
  regular: {
    backgroundColor: siteTheme.backgroundColorPrimary
  },
  dark: {
    backgroundColor: siteTheme.backgroundColorSecondary
  }
}