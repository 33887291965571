import styled from 'styled-components'
import {
  HalignContent,
  Heading1,
  breakpoint
} from '../../layout/mixins'
import Image from 'gatsby-image'
import Button from '../Button'

export const Wrapper = styled.section`
  position: relative;
  min-height: 60vh;
`

export const ImageWrapper = styled(Image)`
  position: absolute;
  width: 100%;
  height: 100%;
`

export const Inner = styled(HalignContent)``

export const Content = styled.div`
  position: relative;
  padding-top: 8rem;
  padding-bottom: ${({ theme }) => theme.blockpadding};

  ${breakpoint.up('tablet')`
    width: 50%;
  `}
`

export const Heading = styled(Heading1)`
  color: ${({ theme }) => theme.textcolorSecondary};
`

export const Preamble = styled.div`
  color: ${({ theme }) => theme.textcolorSecondary};
  margin-bottom: ${({ theme }) => theme.gutterMedium};
`

export const ButtonGroup = styled.div`
  display: flex;
  flex-direction: column;
  align-items: flex-start;

  ${breakpoint.up('tablet')`
    flex-direction: row;
  `}
`

export const StyledButton = styled(Button)`
  min-width: 9rem;
  margin-bottom: ${({ theme }) => theme.gutter};

  ${breakpoint.up('tablet')`
    margin-right: ${({ theme }) => theme.gutter};
    margin-bottom: 0;
  `}

  &:last-of-type {
    margin-right: 0;
    margin-bottom: 0;
  }
`