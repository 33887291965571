import styled from 'styled-components'
import BackgroundImage from 'gatsby-background-image'
import {
  HalignContent,
  Heading2,
  breakpoint,
  ParagraphStyles,
  BlockPaddingVertical,
  NewsTextSmallStyles
} from '../../layout/mixins'

export const Wrapper = styled.section`
  ${BlockPaddingVertical}
  background: ${({ theme }) => theme.backgroundColorSecondary};
  position: relative;
`

export const Inner = styled(HalignContent)``

export const Title = styled(Heading2)``

export const SubTitle = styled.div`
  ${ParagraphStyles}
  color: ${({ theme }) => theme.textcolorPrimary};
  margin-bottom: ${({ theme }) => theme.gutterLarge};
`

export const LinkGrid = styled.div`
  display: grid;
  grid-template-columns: 1fr 1fr 1fr;
  gap: ${({ theme }) => theme.gutter};
`

export const GridItem = styled.div``

export const Image = styled(BackgroundImage)`
  position: relative;
  height: 0;
  padding-top: 100%;
  margin-bottom: ${({ theme }) => theme.gutter};
  box-shadow: 0px 4px 10px rgba(30, 31, 29, 0.2);
`

export const Overlay = styled.div`
  position: absolute;
  width: 100%;
  height: 100%;
  top: 0;
  left: 0;
  transition: ${({ theme }) => `opacity ${theme.transitionRegular}`};
  opacity: 0;

  ${GridItem}:nth-of-type(1) & {
    background: ${({ theme }) => theme.colorAccent};
  }

  ${GridItem}:nth-of-type(2) & {
    background: ${({ theme }) => theme.colorYellow};
  }

  ${GridItem}:nth-of-type(3) & {
    background: ${({ theme }) => theme.colorBlack};
  }

  ${Image}:hover & {
    opacity: 0.88;
  }
`

export const ImageContent = styled.div`
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;

  &:after {
    content: '';
    position: absolute;
    bottom: 0;
    left: 0;
    width: 100%;
    height: 2px;
    transition: ${({ theme }) => `height ${theme.transitionRegular}, opacity ${theme.transitionRegular}`};
  }

  &:hover:after {
    opacity: 0;
  }

  ${GridItem}:nth-of-type(1) &:after {
    background: ${({ theme }) => theme.colorAccent};
  }

  ${GridItem}:nth-of-type(2) &:after {
    background: ${({ theme }) => theme.colorYellow};
  }

  ${GridItem}:nth-of-type(3) &:after {
    background: ${({ theme }) => theme.colorBlack};
  }
`

export const LinkText = styled.div`
  font-size: 1rem;

  ${breakpoint.up('tablet')`
    ${NewsTextSmallStyles}
    font-weight: 700;
  `}  
`