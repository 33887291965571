import styled from 'styled-components'
import BackgroundImage from 'gatsby-background-image'
import { Image } from 'react-datocms'
import CustomLink from '../../components/CustomLink'
import Icon from '../../components/Icon'

import {
  NewsTextSmallStyles,
  NewsTextLargeStyles,
  breakpoint,
  truncateOverflow,
  hyphens,
  Heading3
} from '../../layout/mixins'

export const Grid = styled.div`
  display: grid;
  grid-template-columns: 1fr;
  grid-auto-rows: 16rem;
  grid-gap: ${({ theme }) => theme.gutterLarge};

  ${breakpoint.up('tablet')`
    grid-template-columns: 1fr 1fr;
  `}

  ${breakpoint.up('large')`
    grid-template-columns: 1fr 1fr 1fr;
  `}
`

export const FeedItem = styled.div`
  position: relative;
  border: 2px solid ${({ theme }) => theme.colorBlack};
  border-radius: ${({ theme }) => theme.buttonRadius};
  transition: background ${({ theme }) => theme.transitionRegular};
  cursor: pointer;

  &:hover {
    background: ${({ theme }) => theme.colorBlack};
  }
`

export const ItemImage = styled(BackgroundImage)`
  height: 100%;
`

export const DatoItemImage = styled(Image)`
  position: relative;
  height: 100%;
  width: 100%;
  max-width: unset !important;

  img {
    position: absolute;
    width: 100%;
    height: 100%;
    top: 0;
    left: 0;
    object-fit: cover;
  }
`

export const Gradient = styled.div`
  position: absolute;
  width: 100%;
  height: 100%;
  bottom: 0;
  left: 0;
  background: linear-gradient(180deg, rgba(30, 31, 29, 0) 68.58%, #1E1F1D 100%);
`

export const Content = styled.div`
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  padding: ${({ theme }) => theme.gutterMedium};
`

export const Title = styled.span`
  position: absolute;
  width: 75%;
  bottom: ${({ theme }) => theme.gutterMedium};
  left: ${({ theme }) => theme.gutterMedium};
  ${NewsTextSmallStyles}
  text-align: left;
  ${truncateOverflow(3)}
  ${hyphens}
  font-weight: 600;
  transition: color ${({ theme }) => theme.transitionRegular};

  ${FeedItem}:hover & {
    color: ${({ theme }) => theme.colorWhite};
  }
`

export const Tags = styled.span`
  position: absolute;
  top: ${({ theme }) => theme.gutterMedium};
  left: ${({ theme }) => theme.gutterMedium};
  color: ${({ theme }) => theme.colorBlack};
  font-size: 0.78rem;
  font-weight: 700;
  transition: color ${({ theme }) => theme.transitionRegular};

  ${FeedItem}:hover & {
    color: ${({ theme }) => theme.colorWhite};
  }  
`

export const Plus = styled(Icon)`
  && {
    position: absolute;
    top: ${({ theme }) => theme.gutterMedium};
    right: ${({ theme }) => theme.gutterMedium};
    width: 1.3rem;
    height: 1.3rem;
    transform: rotate(45deg);
    transition: fill ${({ theme }) => theme.transitionRegular};

    ${FeedItem}:hover & {
      fill: ${({ theme }) => theme.colorWhite};
    }    
  }
`

export const ModalContent = styled.div`
  display: flex;
  width: 100%;

  ${breakpoint.up('tablet')`
    min-height: 25rem;
  `}   
`

export const TextItem = styled.div`
  background: ${({ theme }) => theme.colorWhite};
  padding: ${({ theme }) => theme.gutterXXLarge};

  ${breakpoint.up('tablet')`
    width: 50%;
  `}   
`

export const ImageItem = styled.div`
  display: none;

  ${breakpoint.up('tablet')`
    display: block;
    width: 50%;
  `}  
`

export const Question = styled(Heading3)``

export const Answer = styled.span``