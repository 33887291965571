import React from 'react'
import PropTypes from 'prop-types'
import { graphql } from 'gatsby'
import {
  isExternalUrl,
  trimUnits
} from '../../utils/helpers'
import {
  LinkGrid,
  GridItem,
  Image,
  ImageContent,
  Overlay,
  Text,
  LinkText,
  SubText
} from './styles'
import ExternalLink from '../ExternalLink/externalLink'
import CustomLink from '../CustomLink'
import theme from '../../layout/theme'

const InformationRowImage = ({
  links
}) => {

  const {
    breakpoints: {
      phone,
      max
    },
    gutter
  } = theme

  const sizes = `
    (max-width: ${phone}px) calc((100vw - ${4 * trimUnits(gutter)}rem) / 3), 
    (max-width: ${max}px) calc((100vw - ${16 * trimUnits(gutter)}rem) / 3), 
    470px
  `

  return (
    <LinkGrid>
      {links.map((item) => {

        const fluidImage = {
          ...item.image.fluid,
          sizes
        }

        return (
          <GridItem key={item.title}>
            <CustomLink to={item.url}>
              <Image fluid={fluidImage}>
                <Overlay />
                <ImageContent>
                </ImageContent>
                {isExternalUrl(item.url) && <ExternalLink />}
              </Image>
            </CustomLink>
            <Text>
              <LinkText>{item.title}</LinkText>
              <SubText>{item.textContent}</SubText>
            </Text>
          </GridItem>
        )
      })}
    </LinkGrid>
  )
}

export default InformationRowImage

InformationRowImage.propTypes = {
  title: PropTypes.string.isRequired,
  textContent: PropTypes.string,
  links: PropTypes.shape({
    title: PropTypes.string.isRequired,
    url: PropTypes.string.isRequired,
    image: PropTypes.shape({
      fluid: PropTypes.shape({
        aspectRatio: PropTypes.number.isRequired,
        base64: PropTypes.string.isRequired,
        sizes: PropTypes.string.isRequired,
        src: PropTypes.string.isRequired,
        srcSet: PropTypes.string.isRequired
      }).isRequired
    }).isRequired
  }).isRequired
}

InformationRowImage.defaultProps = {
  textContent: 'This is the default sub title.'
}

export const InformationRowImageQuery = graphql`
  fragment InformationRowImageFragment on DatoCmsExternalImageLink {
    url
    title
    textContent
    image {
      fluid(maxWidth: 470, sizes: "107, 178, 214, 359, 470, 940") {
        ...GatsbyDatoCmsFluid
      }
    }    
  }
`